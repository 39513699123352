<template>
    <div class="main-pdding">
        <div class="main-box statistics">
            <div class="sta-label">
                <label>欢迎进入</label>
                <label>后台管理系统</label>
            </div>
            <div class="sta-box">
                <div>
                    <div class="user-name cross-center main-betwee">
                        <img src="@/assets/images/merchantMan/user-name-icon.png">
                        <div class="text-label">
                            <span>
                                用户名
                            </span>
                            <label>{{name}}</label>
                        </div>
                    </div>
                    <div class=" app-num cross-center main-betwee">
                        <img src="@/assets/images/merchantMan/app-num-icon.png">
                        <div class="text-label">
                            <span>
                                可创建小程序数量
                            </span>
                            <label>{{appCal(app_num)}}</label>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="ipone cross-center main-betwee">
                        <img src="@/assets/images/merchantMan/ipone-icon.png">
                        <div class="text-label">
                            <span>
                                手机号
                            </span>
                            <label>{{phone}}</label>
                        </div>
                    </div>
                    <div class="validity cross-center main-betwee">
                        <img src="@/assets/images/merchantMan/accetion-icon.png">
                        <div class="text-label">
                            <span>
                                账号有效期
                            </span>
                            <label>{{time}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {sessionGetKey} from '@/utils/sessionStorage.js'
    export default {
        data() {
            return {
                phone:'',
                name:'',
                app_num:'',
                time:''
            }
        },
        mounted(){
            this.phone = sessionGetKey('phone')
            this.name = sessionGetKey('userName')
            this.app_num = sessionGetKey('app_num')
            this.time = sessionGetKey('time')
        },
        computed:{
            appCal(){
                return function(num){
                    switch (num) {
                        case -1: return '无限制'
                        case 0: return '不可创建'
                        default: return num
                    }
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .statistics {
        background-image: url('~@/assets/images/merchantMan/statisticsBg.png');
        background-size: 850px;
        background-position: 90% 0;
        background-repeat: no-repeat;
    }

    .sta-label {
        label {
            display: block;
            font-size: 40px;
            font-weight: 600;
            margin-top: 10px;
        }
    }

    .sta-box {
        margin-top: 110px;
        display: flex;

        .user-name {
            width: 390px;
            height: 165px;
            background-image: linear-gradient(to right, #4E87FF, #4458FE);
            border-radius: 16px;
            padding: 20px;
        }

        .ipone {
            width: 390px;
            height: 165px;
            background-image: linear-gradient(to right, #0DE5CA, #01D0B5);
            border-radius: 16px;
            padding: 20px;
            margin-left: 10px;
        }

        .app-num {
            width: 390px;
            height: 165px;
            background-image: linear-gradient(to right, #2FC9FC, #14B9EF);
            border-radius: 16px;
            padding: 20px;
            margin-top: 10px;
        }

        .validity {
            width: 390px;
            height: 165px;
            background-image: linear-gradient(to right, #FFDE76, #FFD75A);
            border-radius: 16px;
            padding: 20px;
            margin: 10px 0 0 10px;
        }

        img {
            width: 85px;
            height: 85px;
        }

        .text-label {
            margin-left: 20px;

            span {
                font-size: 22px;
                color: #FFFFFF;
            }

            label {
                display: block;
                font-size: 32px;
                font-weight: 600;
                color: #FFFFFF;
            }
        }
    }

    @media screen and(max-width: 1580px) {
        .sta-box {
            .user-name {
                width: 350px;
                height: 125px;
            }
            .ipone {
                width: 350px;
                height: 125px;
            }
            .app-num {
                width: 350px;
                height: 125px;
            }
            .validity {
                width: 350px;
                height: 125px;
            }
            img {
                width: 60px;
                height: 60px;
            }
            .text-label {
                span {
                    font-size: 20px;
                }
                label {
                    font-size: 26px;
                }
            }
        }
    }
</style>